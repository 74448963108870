import { Testimonials } from 'models/Client'

export const ourClients: string[] = [
  'fagor',
  'ulma',
  'renfe',
  'bbva',
  'iberostar',
  'securitas',
  'cosentino',
  'caf',
  'norauto',
  'mahou',
  // 'uva',
  'talgo',
  // 'uma',
  'reale',
  // 'ule',
  'basf',
  // 'unavarra',
  'tragsa',
  // 'uja',
  'isdefe',
  'ugranada',
  'uab',
  'portBalears',
  // 'pct',
  'navantia',
  'ccoo',
  'ayesa',
  'ajuntamentBarcelona',
  'amaya',
  'amb',
  'oreka',
  'ajuntamentBenidorm',
  // 'navantia',
  'ule',
  // 'isdefe',
  'uva',
  'atexis',
  'mondragon',
]

export const testimonials: Testimonials = {
  testimonials: [
    {
      client: {
        name: 'Gustavo Risso',
        position: 'Responsable de Movilidad y Serv. del Parque Industrial',
        company: 'Cosentino',
        avatar: 'Gustavo-Risso',
        companyLogo: 'cosentino',
      },
      mesagge:
        'En Cosentino estamos comprometidos con la salud y el bienestar de nuestra gente, para juntos, conseguir una empresa más sostenible. Es por esto que decidimos implementar un nuevo sistema de coche compartido y una aplicación móvil para nuestros empleados, lo que les permitirá reducir su huella de carbono y ahorrar dinero en sus desplazamientos al trabajo. Con Ciclogreen hemos encontrado la solución que estábamos buscando. Además, hemos establecido un programa de incentivos y premios para fomentar la utilización del sistema compartido y estamos optimizando nuestros espacios de estacionamiento para priorizar los vehículos sostenibles. Creemos que pequeñas acciones como estas pueden tener un gran impacto en la lucha contra el cambio climático y estamos orgullosos de liderar el camino hacia un futuro más verde y sostenible.',
    },
    {
      client: {
        name: 'Pablo Olivares Phélix',
        position: 'Ingeniero de caminos del área de movilidad y sostenibilidad',
        company: 'AOPJA',
        avatar: 'pabloolivares-aopja',
        companyLogo: 'aopja',
      },
      mesagge:
        'Lo más importante del Plan de Movilidad Sostenible ha sido como, gracias al apoyo de la aplicación de Ciclogreen, los compañeros de la Agencia de Obra Pública de la Junta de Andalucía han asumido la movilidad sostenible como algo positivo para ellos y para nuestro entorno social y medioambiental, más allá de los motivos iniciales de cada uno (retos y premios de Ciclogreen, ahorro de tiempo y coste, salud y bienestar, etc.), convirtiéndolo finalmente en un hábito. “Los temas de movilidad no son temas técnicos sino vitales.',
    },
    {
      client: {
        name: 'José Antonio Santoyo Román',
        position: 'Investigador de Smart-Campus',
        company: 'Universidad de Málaga',
        avatar: 'joseantoniosantoyo-uma',
        companyLogo: 'uma',
      },
      mesagge:
        'La implantación de Ciclogreen en la Universidad ha originado una mayor concienciación en la movilidad del Campus, además, la gamificación de la app ha generado que la Comunidad Universitaria se percate de que realmente es una parte importante en la lucha del cambio climático.',
    },
  ],
}

export const odsColumns: string[] = ['11', '13']

export const benefitsMock: string[] = [
  'traffic',
  'co2',
  'money',
  'optimization',
]
