import { ButtonVariants } from '../../constants'
import Hamburguer from '../Atoms/Buttons/Hamburguer/Hamburguer'
import Link from '../Atoms/Link/Link'

import { useEffect, useState } from 'react'

import classNames from 'classnames'
import RoundedButton from 'components/Atoms/Buttons/RoundedButton/RoundedButton'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

export interface HeaderProps {
  selected?: string
  onClick: (section: string) => void
  logo: string
  className?: string
  hideOptions?: boolean
}

const Header = ({
  selected,
  onClick,
  logo,
  className,
  hideOptions,
}: HeaderProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const navClassname = classNames('bg-white', 'p-4', {
    'flex flex-col flex-grow pb-4 md:pb-0 md:flex md:justify-center md:flex-row':
      isOpen,
    'hidden md:flex md:flex-row md:justify-center flex-col md:align-center':
      !isOpen,
  })
  const linkClassname = classNames({
    'ml-6': !isOpen,
  })

  const closeMenu = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        isOpen &&
        !event.target.closest('.nav-container') &&
        !event.target.closest('.hamburger-button')
      ) {
        closeMenu()
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isOpen])

  const handleClick = (section: string) => {
    onClick(section)
    closeMenu()
  }
  return (
    <div className={className}>
      <div className="antialiased bg-white dark-mode:bg-white">
        <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-white nav-container">
          <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
            <div className="flex flex-row items-center justify-between p-4">
              <RouterLink to="/">
                <img
                  alt="logo"
                  src={`${process.env.REACT_APP_URL_S3}/images/${logo}`}
                  width={152}
                  height={23}
                />
              </RouterLink>
              <Hamburguer
                className="md:hidden focus:outline-none focus:shadow-outline"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </div>
            <nav className={navClassname}>
              {!hideOptions && (
                <>
                  <Link
                    selected={selected === 'benefits'}
                    className={linkClassname}
                    onClick={() => handleClick('benefits')}
                  >
                    {t('header.benefits')}
                  </Link>
                  <Link
                    selected={selected === 'howCarpooling'}
                    className={linkClassname}
                    onClick={() => handleClick('howCarpooling')}
                  >
                    {t('header.howCarpooling')}
                  </Link>
                  <a
                    href="https://app.hubspot.com/meetings/gregoriomagno/reunionciclogreen"
                    target="_blank"
                    rel="noreferrer"
                    className="flex justify-center"
                  >
                    <Link
                      selected={selected === 'askDemo'}
                      className={linkClassname}
                      onClick={() => handleClick('askDemo')}
                    >
                      {t('header.askDemo')}
                    </Link>
                  </a>
                  <Link
                    selected={selected === 'clients'}
                    className={linkClassname}
                    onClick={() => handleClick('clients')}
                  >
                    {t('header.clients')}
                  </Link>
                  <Link
                    selected={selected === 'contact'}
                    className={linkClassname}
                    onClick={() => handleClick('contact')}
                  >
                    {t('header.contact')}
                  </Link>
                  <a
                    href="https://blog.ciclogreen.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="flex justify-center"
                  >
                    <Link
                      selected={selected === 'blog'}
                      className={linkClassname}
                      onClick={() => {}}
                    >
                      {t('header.blog')}
                    </Link>
                  </a>
                </>
              )}
              <a
                className={`${linkClassname} md:mt-0 mt-5 w-full md:w-auto flex justify-center`}
                href="https://landing.ciclogreen.com/meetings/gregoriomagno/reunionciclogreen?utm_campaign=Compartir Coche al Trabajo 2024&utm_source=compartir-coche&utm_medium=landing&utm_content=page"
                target="_blank"
                rel="noreferrer"
              >
                <RoundedButton variant={ButtonVariants.PRIMARY}>
                  {t('btn.contact')}
                </RoundedButton>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
