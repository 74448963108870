import { useEffect } from 'react'

import { ViewportProvider } from 'context/viewportProvider'
import Navigation from 'routes/Navigation'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-tooltip/dist/react-tooltip.css'

function App() {
  useEffect(() => {
    const scale = 1 / window.devicePixelRatio

    const viewport = document.querySelector('meta[name="viewport"]')
    console.log(viewport)
    if (!viewport) return
    viewport.setAttribute(
      'content',
      `width=device-width, initial-scale=${scale}, maximum-scale=1.0, user-scalable=0`,
    )
    console.log(viewport)
  }, [])
  return (
    <ViewportProvider>
      <Navigation />
    </ViewportProvider>
  )
}

export default App
