import { RoundedButtonProps } from './useRoundedButton'
import { ButtonVariants } from '../../../../constants'

import React from 'react'

import ClassNames from 'classnames'

const RoundedButtonView = ({
  variant,
  disabled,
  className,
  onClick,
  loading,
  type,
  children,
}: React.PropsWithChildren<RoundedButtonProps>) => {
  const btnClass = ClassNames(
    'py-4',
    'px-10',
    'rounded-3xl',
    'hover:brightness-105',
    'transition ease-in-out',
    'active:brightness-95',
    'justify-center',
    'shadow-md',
    'hover:scale-105',
    {
      'text-white': variant === ButtonVariants.PRIMARY,
      'bg-white':
        variant !== ButtonVariants.PRIMARY &&
        variant !== ButtonVariants.TERTIARY,
      'bg-blue-800': variant === ButtonVariants.PRIMARY,
      'pointer-events-none opacity-50': disabled || loading,
      'border border-blue-800':
        variant !== ButtonVariants.PRIMARY &&
        variant !== ButtonVariants.TERTIARY,
      'hover:bg-blue-800': variant === ButtonVariants.SECONDARY,
      'hover:text-white':
        variant !== ButtonVariants.PRIMARY &&
        variant !== ButtonVariants.TERTIARY,
      'bg-green-800': variant === ButtonVariants.TERTIARY,
      'text-blue-800':
        variant !== ButtonVariants.PRIMARY &&
        variant !== ButtonVariants.TERTIARY,
      'text-black': variant === ButtonVariants.TERTIARY,
    },
  )
  return (
    <button
      className={`${btnClass} ${className}`}
      data-testid="button"
      disabled={loading || disabled}
      type={type}
      onClick={onClick}
    >
      {loading && (
        <svg className="h-4 w-4 animate-spin mr-2" viewBox="3 3 18 18">
          <path
            className="fill-grey-600"
            d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
          ></path>
          <path
            className="fill-grey-500"
            d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
          ></path>
        </svg>
      )}
      <span className="font-semibold">{children}</span>
    </button>
  )
}

export default RoundedButtonView
